@import (reference) "../../app-skeleton.less";

.role-membership-editor {
    position: relative;
    .scroll-padding-bottom;

    .filter-list {
        display: flex;
        flex-direction: row;

        .filter-text-field {
            flex-grow: 7;
        }
    }

    .with-circle {
        position: relative;
        &:before {
            content: ' ';
            background-color: @gray5;
            position: absolute;
            left: @app-skeleton-table-left-padding;
            top: @app-skeleton-table-row-height / 2; // usual 50% did not work on Edge
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }

        &.orange {
            &:before {
                background-color: @orange;
            }
        }

        &.blue {
            &:before {
                background-color: @blue;
            }
        }

        &.green {
            &:before {
                background-color: @green;
            }
        }

        &.purple {
            &:before {
                background-color: @purple;
            }
        }
    }

    .table {
        // Widths of columns
        th:first-child, td:first-child {
            width: 33px + @app-skeleton-table-left-padding;
        }

        th:nth-child(2), td:nth-child(2) {
            width: 25%;
        }

        th:nth-child(3), td:nth-child(3) {
            width: 25%;
        }

        thead {
            tr {
                th {
                    &:first-child {
                        text-align: center;
                        color: @gray5;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &.with-circle {
                        position: relative;

                        .initials {
                            .aria-regular;
                            display: block;
                            position: absolute;
                            width: 25px;
                            left: @app-skeleton-table-left-padding;
                            top: @app-skeleton-table-row-height / 2; // usual 50% did not work on Edge
                            transform: translateY(-50%);
                            color: white;
                            text-align: center;
                            text-transform: uppercase;
                            font-size: 11px;
                        }
                    }

                    &.user {
                        .text-overflow-table-cell;
                    }

                    &.name {
                        .text-overflow-table-cell;
                    }

                    &.role {
                        display: flex;
                        align-items: center;

                        .role-label {
                            padding-left: 15px;
                        }
                    }

                    &.add {
                        padding-right: @app-skeleton-table-right-padding;
                        text-align: right;

                        aui-text-button {
                            button {
                                background-color: @blue;
                            }
                        }
                    }
                }
            }
        }

        &.unauthorised-users {
            thead {
                display: none;
            }
        }
    }

    .filter-list {
        position: absolute;

        .aui-text-field {
            padding-left: 41px + @app-skeleton-table-left-padding;
        }

        &:before {
            left: @app-skeleton-table-left-padding;
        }
    }

    h3, h4 {
        padding-left: 41px + @app-skeleton-table-left-padding;
    }

    h3 {
        display: none;

        &.header-authorised {
            position: absolute;
            // Header + filter input + header for unauthorised users +
            // single entry in unauthorised users
            top: @app-skeleton-filtered-table-header-row-gap + @app-skeleton-filtered-table-filter-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-regular-row-gap;
            left: 0;
        }
    }

    .unauthorised-users-layer {
        display: none;
        position: absolute;
        // Header + filter input
        top: @app-skeleton-filtered-table-header-row-gap + @app-skeleton-filtered-table-filter-row-gap;
        left: 0;
        width: 100%;
    }

    &.filter-not-empty {
        h3 {
            display: block;
        }

        &:not(.read-only) {
            .filtered-table {
                th {
                    // filter input + header for unauthorised users +
                    // single entry in unauthorised users + header for
                    // authorised + initial padding on the header
                    padding-bottom: @app-skeleton-filtered-table-filter-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-regular-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-cell-padding;
                }
            }

            .unauthorised-users-layer {
                display: block;
            }
        }
    }
}

@enable-css-reset: false;